var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.$style.natsection }, [
    (_vm.isMpls && !_vm.rules.length) || !_vm.canIPv4
      ? _c(
          "div",
          [
            _c("ManagedSection", {
              attrs: {
                title: _vm.$gettext("Setup NAT rules"),
                section: _vm.$gettext("NAT rules")
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "subtitle",
                    fn: function() {
                      return [
                        _vm.iplink.mpls
                          ? _c(
                              "h3",
                              {
                                directives: [
                                  { name: "translate", rawName: "v-translate" }
                                ]
                              },
                              [
                                _vm._v(
                                  "\n                    An MPLS is deployed and manages this IP link.\n                "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.canIPv4
                          ? _c(
                              "h3",
                              {
                                directives: [
                                  { name: "translate", rawName: "v-translate" }
                                ]
                              },
                              [
                                _vm._v(
                                  "\n                    This access is configured on IPv6 stack.\n                "
                                )
                              ]
                            )
                          : _vm._e()
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                2718197986
              )
            })
          ],
          1
        )
      : _c(
          "div",
          [
            _c("Advice", {
              attrs: {
                title: _vm.$gettext("NAT management"),
                "link-url":
                  "https://kiosque.unyc.io/document/administration-des-routeurs/nat-adr/"
              },
              scopedSlots: _vm._u([
                {
                  key: "text",
                  fn: function() {
                    return [
                      _c(
                        "p",
                        {
                          directives: [
                            { name: "translate", rawName: "v-translate" }
                          ]
                        },
                        [
                          _vm._v(
                            "\n                    A Network Address Translation (NAT) rule allows an on-premises device behind the router to be accessible from the outside internet network.\n                "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("p", [
                        _c(
                          "b",
                          {
                            directives: [
                              { name: "translate", rawName: "v-translate" }
                            ]
                          },
                          [
                            _vm._v(
                              "\n                        Did you know : To allow all source IPs you will have to enter 0.0.0.0/0 in the field. To increase the security of your routed devices think about change the default passwords as they will be accessible to all from internet.\n                    "
                            )
                          ]
                        )
                      ])
                    ]
                  },
                  proxy: true
                },
                {
                  key: "example",
                  fn: function() {
                    return [
                      _c(
                        "p",
                        {
                          directives: [
                            { name: "translate", rawName: "v-translate" }
                          ]
                        },
                        [
                          _vm._v(
                            "\n                    Exemple: To access a webcam on the local network having the IP address 192.168.1.100, from the source IP 6.7.8.9, via the port 1234 of the equipment 185.86.88.85. You will need to set the following rule:\n                "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("ul", [
                        _c(
                          "li",
                          {
                            directives: [
                              { name: "translate", rawName: "v-translate" }
                            ]
                          },
                          [
                            _vm._v(
                              "\n                        Source IP: 6.7.8.9\n                    "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "li",
                          {
                            directives: [
                              { name: "translate", rawName: "v-translate" }
                            ]
                          },
                          [
                            _vm._v(
                              "\n                        Public IP: 185.86.88.85\n                    "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "li",
                          {
                            directives: [
                              { name: "translate", rawName: "v-translate" }
                            ]
                          },
                          [
                            _vm._v(
                              "\n                        Public port: 1234\n                    "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "li",
                          {
                            directives: [
                              { name: "translate", rawName: "v-translate" }
                            ]
                          },
                          [
                            _vm._v(
                              "\n                        Private IP: 192.168.1.100\n                    "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "li",
                          {
                            directives: [
                              { name: "translate", rawName: "v-translate" }
                            ]
                          },
                          [
                            _vm._v(
                              "\n                        Private port: 1234\n                    "
                            )
                          ]
                        )
                      ])
                    ]
                  },
                  proxy: true
                }
              ])
            }),
            _vm._v(" "),
            _c("UpdatedSection", { attrs: { section: "nat" } }),
            _vm._v(" "),
            _vm.rules.length
              ? _c(
                  "div",
                  [
                    _c(
                      "div",
                      { staticClass: "row mt-3 mb-4" },
                      [
                        _c("Title", {
                          attrs: { title: _vm.$gettext("Setup NAT rules") }
                        }),
                        _vm._v(" "),
                        _c("Button", {
                          staticClass: "col-4 ml-auto d-none d-lg-flex",
                          class: [_vm.$style.rightbutton],
                          attrs: {
                            role: "primary",
                            text: _vm.$gettext("Add NAT rule"),
                            icon: "add",
                            size: "large",
                            disabled: _vm.isMpls
                          },
                          on: {
                            click: function($event) {
                              return _vm.addNatRule()
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("Button", {
                          staticClass: "d-lg-none",
                          class: _vm.$style.bottombutton,
                          attrs: {
                            role: "icon_primary",
                            icon: "add",
                            disabled: _vm.isMpls
                          },
                          on: {
                            click: function($event) {
                              return _vm.addNatRule()
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "row", class: _vm.$style.search },
                      [
                        _c("Field", {
                          class: _vm.$style.searchField,
                          attrs: {
                            placeholder: _vm.$gettext("Search for a NAT rule"),
                            name: "search",
                            size: "small",
                            "icon-left": "search"
                          },
                          model: {
                            value: _vm.search,
                            callback: function($$v) {
                              _vm.search = $$v
                            },
                            expression: "search"
                          }
                        }),
                        _vm._v(" "),
                        _c("ComponentSimpleFilters", {
                          attrs: { "filter-choices": _vm.statusFiltersValues },
                          model: {
                            value: _vm.filteringByStatus,
                            callback: function($$v) {
                              _vm.filteringByStatus = $$v
                            },
                            expression: "filteringByStatus"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("NatList", {
                      staticClass: "row",
                      attrs: {
                        "can-edit-rule": !_vm.isMpls,
                        "can-remove-rule": "",
                        search: _vm.search,
                        "filter-by-status": _vm.filteringByStatus
                      }
                    })
                  ],
                  1
                )
              : _c(
                  "div",
                  [
                    _c("Empty", {
                      attrs: {
                        steps: _vm.emptysteps,
                        image: "nat",
                        subtitle: _vm.$gettext("Manage NAT rules")
                      },
                      on: {
                        link_clicked: function($event) {
                          return _vm.addNatRule()
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("Button", {
                      staticClass: "mt-4 col-4 d-none d-lg-flex",
                      attrs: {
                        role: "primary",
                        text: _vm.$gettext("Add NAT rule"),
                        icon: "add",
                        size: "large",
                        disabled: _vm.isMpls
                      },
                      on: {
                        click: function($event) {
                          return _vm.addNatRule()
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("Button", {
                      staticClass: "d-lg-none",
                      class: _vm.$style.bottombutton,
                      attrs: {
                        role: "icon_primary",
                        icon: "add",
                        disabled: _vm.isMpls
                      },
                      on: {
                        click: function($event) {
                          return _vm.addNatRule()
                        }
                      }
                    })
                  ],
                  1
                )
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }