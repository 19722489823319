var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "row p-4 mb-3 mx-1 mx-xl-0",
      class: [_vm.$style.nat_listitem, _vm.$style.item_store]
    },
    [
      _c(
        "div",
        {
          staticClass: "px-xl-0 col-12 pb-1 col-xl-1 pb-xl-0 align-self-center"
        },
        [
          _c(
            "div",
            {
              directives: [{ name: "translate", rawName: "v-translate" }],
              staticClass: "d-xl-none",
              class: [_vm.$style.body2, _vm.$style.title]
            },
            [_vm._v("\n            Rule name\n        ")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "flex-grow-1",
              class: [_vm.$style.c1, _vm.$style.bold, _vm.$style.rule_name]
            },
            [_vm._v("\n            " + _vm._s(_vm.rule.name) + "\n        ")]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-12 pb-1 col-xl-1 pb-xl-0 align-self-center" },
        [
          _c(
            "div",
            {
              directives: [{ name: "translate", rawName: "v-translate" }],
              staticClass: "d-xl-none",
              class: [_vm.$style.body2, _vm.$style.title]
            },
            [_vm._v("\n            Protocol\n        ")]
          ),
          _vm._v(" "),
          _c("div", { class: _vm.$style.c1 }, [
            _vm._v("\n            " + _vm._s(_vm.protocol) + "\n        ")
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-12 pb-1 col-xl-2 pb-xl-0 align-self-center" },
        [
          _c(
            "div",
            {
              directives: [{ name: "translate", rawName: "v-translate" }],
              staticClass: "d-xl-none",
              class: [_vm.$style.body2, _vm.$style.title]
            },
            [_vm._v("\n            Public IP\n        ")]
          ),
          _vm._v(" "),
          _c("div", { class: _vm.$style.c1 }, [
            _vm._v(
              "\n            " +
                _vm._s(_vm.rule.public_destination.ip) +
                "\n        "
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-12 pb-1 col-xl-2 pb-xl-0 align-self-center" },
        [
          _c(
            "div",
            {
              directives: [{ name: "translate", rawName: "v-translate" }],
              staticClass: "d-xl-none",
              class: [_vm.$style.body2, _vm.$style.title]
            },
            [_vm._v("\n            Public Port\n        ")]
          ),
          _vm._v(" "),
          _c("div", { class: _vm.$style.c1 }, [
            _vm._v(
              "\n            " +
                _vm._s(_vm.rule.public_destination.ports) +
                "\n        "
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-12 pb-1 col-xl-2 pb-xl-0 align-self-center" },
        [
          _c(
            "div",
            {
              directives: [{ name: "translate", rawName: "v-translate" }],
              staticClass: "d-xl-none",
              class: [_vm.$style.body2, _vm.$style.title]
            },
            [_vm._v("\n            Private IP\n        ")]
          ),
          _vm._v(" "),
          _c("div", { class: _vm.$style.c1 }, [
            _vm._v(
              "\n            " +
                _vm._s(_vm.rule.lan_destination.ip) +
                "\n        "
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-12 pb-1 col-xl-2 pb-xl-0 align-self-center" },
        [
          _c(
            "div",
            {
              directives: [{ name: "translate", rawName: "v-translate" }],
              staticClass: "d-xl-none",
              class: [_vm.$style.body2, _vm.$style.title]
            },
            [_vm._v("\n            Private Port\n        ")]
          ),
          _vm._v(" "),
          _c("div", { class: _vm.$style.c1 }, [
            _vm._v(
              "\n            " +
                _vm._s(_vm.rule.lan_destination.ports) +
                "\n        "
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-12 pb-1 col-xl-1 pb-xl-0 align-self-center" },
        [
          _c(
            "div",
            {
              directives: [{ name: "translate", rawName: "v-translate" }],
              staticClass: "d-xl-none",
              class: [_vm.$style.body2, _vm.$style.title]
            },
            [_vm._v("\n            State\n        ")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              class: _vm.$style.flyinginfo,
              attrs: { "data-content": _vm._f("statusRule")(_vm.rule) }
            },
            [
              _c("Choice", {
                staticClass: "mt-1 mt-xl-0",
                attrs: {
                  disabled: !_vm.canEdit,
                  name: "enable_rule",
                  type: "switchButton"
                },
                on: {
                  click: function($event) {
                    return _vm.$emit("toggle_activation")
                  }
                },
                model: {
                  value: _vm.rule.enabled,
                  callback: function($$v) {
                    _vm.$set(_vm.rule, "enabled", $$v)
                  },
                  expression: "rule.enabled"
                }
              })
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "\n            px-xl-0 offset-0 mt-4 col-xl-1 m-xl-0 d-xl-flex\n            justify-content-xl-end align-self-center",
          class: [_vm.$style.bold, _vm.$style.actions]
        },
        [
          _vm.canEdit
            ? _c(
                "div",
                {
                  staticClass: "d-none d-xl-flex",
                  class: [_vm.$style.svg, _vm.$style.flyinginfo],
                  attrs: { "data-content": _vm.$gettext("Edit NAT rule") },
                  on: {
                    click: function($event) {
                      return _vm.$emit("edit_rule")
                    }
                  }
                },
                [
                  _c("MasterSvg", {
                    attrs: {
                      use: "underline-pen",
                      width: "20px",
                      height: "20px"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.canEdit
            ? _c(
                "div",
                {
                  staticClass: "d-xl-none",
                  on: {
                    click: function($event) {
                      return _vm.$emit("edit_rule")
                    }
                  }
                },
                [
                  _c("translate", [
                    _vm._v("\n                Edit NAT rule\n            ")
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.canRemove
            ? _c(
                "div",
                {
                  staticClass: "d-none ml-3 d-xl-flex",
                  class: [_vm.$style.svg, _vm.$style.flyinginfo],
                  attrs: { "data-content": _vm.$gettext("Delete NAT rule") },
                  on: {
                    click: function($event) {
                      return _vm.$emit("delete_rule")
                    }
                  }
                },
                [
                  _c("MasterSvg", {
                    attrs: { use: "trash-bin", width: "20px", height: "20px" }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.canRemove
            ? _c(
                "div",
                {
                  staticClass: "mt-3 d-xl-none",
                  on: {
                    click: function($event) {
                      return _vm.$emit("delete_rule")
                    }
                  }
                },
                [
                  _c("translate", [
                    _vm._v("\n                Delete NAT rule\n            ")
                  ])
                ],
                1
              )
            : _vm._e()
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }