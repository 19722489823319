<template>
    <div
        class="row p-4 mb-3 mx-1 mx-xl-0"
        :class="[$style.nat_listitem, $style.item_store]"
    >
        <div class="px-xl-0 col-12 pb-1 col-xl-1 pb-xl-0 align-self-center">
            <div
                v-translate
                class="d-xl-none"
                :class="[$style.body2, $style.title]"
            >
                Rule name
            </div>
            <div
                class="flex-grow-1"
                :class="[$style.c1, $style.bold, $style.rule_name]"
            >
                {{ rule.name }}
            </div>
        </div>
        <div class="col-12 pb-1 col-xl-1 pb-xl-0 align-self-center">
            <div
                v-translate
                class="d-xl-none"
                :class="[$style.body2, $style.title]"
            >
                Protocol
            </div>
            <div :class="$style.c1">
                {{ protocol }}
            </div>
        </div>
        <div class="col-12 pb-1 col-xl-2 pb-xl-0 align-self-center">
            <div
                v-translate
                class="d-xl-none"
                :class="[$style.body2, $style.title]"
            >
                Public IP
            </div>
            <div :class="$style.c1">
                {{ rule.public_destination.ip }}
            </div>
        </div>
        <div class="col-12 pb-1 col-xl-2 pb-xl-0 align-self-center">
            <div
                v-translate
                class="d-xl-none"
                :class="[$style.body2, $style.title]"
            >
                Public Port
            </div>
            <div :class="$style.c1">
                {{ rule.public_destination.ports }}
            </div>
        </div>
        <div class="col-12 pb-1 col-xl-2 pb-xl-0 align-self-center">
            <div
                v-translate
                class="d-xl-none"
                :class="[$style.body2, $style.title]"
            >
                Private IP
            </div>
            <div :class="$style.c1">
                {{ rule.lan_destination.ip }}
            </div>
        </div>
        <div class="col-12 pb-1 col-xl-2 pb-xl-0 align-self-center">
            <div
                v-translate
                class="d-xl-none"
                :class="[$style.body2, $style.title]"
            >
                Private Port
            </div>
            <div :class="$style.c1">
                {{ rule.lan_destination.ports }}
            </div>
        </div>
        <div class="col-12 pb-1 col-xl-1 pb-xl-0 align-self-center">
            <div
                v-translate
                class="d-xl-none"
                :class="[$style.body2, $style.title]"
            >
                State
            </div>
            <div
                :class="$style.flyinginfo"
                :data-content="rule | statusRule"
            >
                <Choice
                    v-model="rule.enabled"
                    :disabled="!canEdit"
                    name="enable_rule"
                    type="switchButton"
                    class="mt-1 mt-xl-0"
                    @click="$emit('toggle_activation')"
                />
            </div>
        </div>
        <div
            class="
                px-xl-0 offset-0 mt-4 col-xl-1 m-xl-0 d-xl-flex
                justify-content-xl-end align-self-center"
            :class="[$style.bold, $style.actions]"
        >
            <div
                v-if="canEdit"
                class="d-none d-xl-flex"
                :class="[$style.svg, $style.flyinginfo]"
                :data-content="$gettext('Edit NAT rule')"
                @click="$emit('edit_rule')"
            >
                <MasterSvg
                    use="underline-pen"
                    width="20px"
                    height="20px"
                />
            </div>
            <div
                v-if="canEdit"
                class="d-xl-none"
                @click="$emit('edit_rule')"
            >
                <translate>
                    Edit NAT rule
                </translate>
            </div>
            <div
                v-if="canRemove"
                class="d-none ml-3 d-xl-flex"
                :class="[$style.svg, $style.flyinginfo]"
                :data-content="$gettext('Delete NAT rule')"
                @click="$emit('delete_rule')"
            >
                <MasterSvg
                    use="trash-bin"
                    width="20px"
                    height="20px"
                />
            </div>
            <div
                v-if="canRemove"
                class="mt-3 d-xl-none"
                @click="$emit('delete_rule')"
            >
                <translate>
                    Delete NAT rule
                </translate>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import Choice from '@/components/common/choice.vue';
import MasterSvg from './master-svg.vue';

export default {
    components: {
        MasterSvg,
        Choice,
    },
    filters: {
        statusRule(rule) {
            if (rule.enabled) {
                return Vue.prototype.$gettext('Enabled');
            }
            return Vue.prototype.$gettext('Disabled');
        },
    },
    props: {
        canEdit: {
            type: Boolean,
            default: true,
        },
        canRemove: {
            type: Boolean,
            default: true,
        },
        rule: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapGetters('equipment', [
            'linkequipmentconfoptions',
        ]),
        protocol() {
            let protocol = '';

            if (this.linkequipmentconfoptions && this.linkequipmentconfoptions.actions) {
                const protocols = this.linkequipmentconfoptions.actions.PUT.nat
                    .child.children.protocol.choices;

                protocol = protocols.filter(
                    item => item.value === this.rule.protocol,
                )[0].display_name;
            }

            return protocol;
        },
    },
};
</script>

<style lang="scss" module>
.nat_listitem {
    display: flex;
    border-radius: 4px;

    &.item_store {
        transition: 0.3s ease all;
        animation: fade-up 0.3s ease-out;

        @include themify($themes) {
            box-shadow: 0 0 8px 0 rgba(themed('secondary_color'), 0.3);
        }

        &:hover {
            @include themify($themes) {
                box-shadow: 0 0 8px 0 rgba(themed('secondary_color'), 0.6);
            }
        }
    }

    .rule_name {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .title {
        color: #A0A0A0;
        text-transform: uppercase;
    }

    .bold {
        font-weight: bold !important;
    }

    .prettyswitchText {
        @include respond-from(xl) {
            position: absolute;
            transform: translateY(-50%);
        }
    }

    .flyinginfo {
        position: relative;
        display: flex;
        width: fit-content;

        &:before {
            position: absolute;
            top: 100%;
            left: 50%;
            z-index: 5;
            display: none;
            width: 10px;
            height: 10px;
            margin-top: 2px;
            content: " ";
            background: white;
            transform: rotate(45deg) translate(-25%, 25%);
        }

        &:after {
            position: absolute;
            top: calc(100% + 7px);
            left: 50%;
            z-index: 4;
            display: none;
            width: max-content;
            width: intrinsic;
            padding: 5px 20px;
            font-size: 12px;
            font-style: normal;
            font-weight: normal;
            line-height: 1.57;
            color: black;
            text-align: center;
            white-space: pre;
            content: attr(data-content);
            background-color: white;
            border-radius: 4px;
            transition: 0.3s ease all;
            transform: translateX(-50%);

            @include themify($themes) {
                box-shadow: 0 0 10px 0 rgba(themed('primary_color'), 0.2);
            }
        }

        &:hover {
            &:after,
            &:before {
                display: block;
                transition: 0.3s ease all;
            }
        }
    }

    .actions {
        width: auto;
        opacity: 0;

        @include themify($themes) {
            color: themed('primary_color');
            fill: themed('primary_color');
        }

        @include under-to(xl) {
            width: fit-content;
            opacity: 1;

            span {
                cursor: pointer;
            }
        }

        .svg {
            width: 20px;
            height: 20px;
            cursor: pointer;
        }
    }

    &.item_store:hover .actions {
        opacity: 1;
    }
}

.main {
    .nat_listitem {
        @include under-to(xl) {
            .body2 {
                font-size: 12px;
                font-style: normal;
                font-weight: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: normal;
            }

            .c1 {
                margin-top: 5px;
                font-size: 16px;
                font-style: normal;
                font-weight: normal;
                font-stretch: normal;
                line-height: 1.22;
                color: #000;
                letter-spacing: normal;
            }
        }
    }
}
</style>
