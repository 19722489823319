<template>
    <!-- eslint-disable max-len -->
    <div :class="$style.general">
        <div
            v-if="alertsEquipment.length"
            class="row"
        >
            <div class="col">
                <Banner
                    v-for="(alert, index) in alertsEquipment"
                    :key="index"
                    icon="solid-info"
                    type="error"
                    :can-be-closed="false"
                >
                    <span>{{ alert.message }}</span>
                    <template v-slot:action>
                        <Button
                            role="link"
                            :text="alert.button.text"
                            size="large"
                            :class="$style.b1"
                            @click="alert.button.click"
                        />
                    </template>
                </Banner>
            </div>
        </div>
        <div
            v-if="readonly"
            class="row"
        >
            <div class="col">
                <ReadonlyBanner />
            </div>
        </div>
        <div
            v-if="!linkequipment.discharged
                && show_conf_template_banner
            "
            class="row"
        >
            <div class="col">
                <Banner
                    id="apply_template"
                    icon="solid-info"
                    type="info"
                    :can-be-closed="false"
                >
                    <translate tag="b">
                        Available templates:
                    </translate>
                    <translate>
                        You can use a previously saved template to help you configure this equipment.
                    </translate>
                    <template v-slot:action>
                        <Button
                            role="link"
                            :text="$gettext('Choose a template')"
                            size="large"
                            :class="$style.b1"
                            @click="showConfTemplateChoicesModal"
                        />
                    </template>
                </Banner>
            </div>
        </div>
        <div
            v-if="!linkequipment.discharged
                && show_change_equipment_banner"
            class="row"
        >
            <div class="col">
                <Banner
                    id="change_equipment"
                    icon="solid-info"
                    type="info"
                    :can-be-closed="false"
                >
                    <translate tag="b">
                        Equipment not recommended:
                    </translate>

                    <template v-if="notRecommendedEquipmentReason">
                        {{ notRecommendedEquipmentReason }}
                    </template>
                    <template v-else>
                        <translate>
                            The flow rate is not supported by this equipment.
                        </translate>
                    </template>

                    <template v-slot:action>
                        <Button
                            role="link"
                            :text="$gettext('Change equipment')"
                            size="large"
                            :class="$style.b1"
                            @click="showAttachModal(linkequipment)"
                        />
                    </template>
                </Banner>
            </div>
        </div>
        <div
            v-if="!linkequipment.discharged
                && show_security_banner
            "
            class="row"
        >
            <div class="col">
                <Banner
                    id="security"
                    type="help"
                    :small-icon="false"
                    icon="ic-idea"
                >
                    <translate tag="b">
                        Possible security:
                    </translate>
                    <translate>
                        One or more additional access links are available on this site to enable you to secure this installation.
                    </translate>
                    <template v-slot:action>
                        <Button
                            role="link"
                            :text="$gettext('Secure the installation')"
                            size="large"
                            :class="$style.b1"
                            @click="showSecurityModal"
                        />
                    </template>
                </Banner>
            </div>
        </div>
        <div
            v-else-if="!linkequipment.discharged
                && show_eligibility_banner
            "
            class="row"
        >
            <div class="col">
                <Banner
                    id="eligibility"
                    type="help"
                    :small-icon="false"
                    icon="ic-idea"
                >
                    <translate tag="b">
                        Securing:
                    </translate>
                    <translate>
                        To secure your installation you must have a second access link.
                    </translate>
                    <template v-slot:action>
                        <Button
                            role="link"
                            :text="$gettext('Make eligibility')"
                            size="large"
                            :class="$style.b1"
                            @click="redirectToEligibility"
                        />
                    </template>
                </Banner>
            </div>
        </div>
        <div
            v-if="!linkequipment.discharged && missingWanConfiguration"
            class="row"
        >
            <div class="col">
                <Banner
                    icon="modem-4g"
                    type="help"
                    can-be-closed
                    main-width-icon="40px"
                    main-height-icon="40px"
                    :small-icon="false"
                >
                    <div
                        v-translate
                        :class="$style.b4gTitle"
                    >
                        Backup 4G is waiting for deployment
                    </div>
                    <translate>
                        To deploy 4G backup, you need to attach a compatible device and then deploy your device's configuration.
                    </translate>
                    <template v-slot:action>
                        <Button
                            role="link"
                            :text="$gettext('Attach equipment')"
                            size="large"
                            :class="$style.b1"
                            @click="$emit('show-attach-backup-modem-modal')"
                        />
                    </template>
                </Banner>
            </div>
        </div>
        <div
            v-if="!isVrrp"
            class="row"
        >
            <div class="col">
                <div :class="$style.box">
                    <GeneralEquipmentSpecs />
                </div>
            </div>
        </div>
        <div
            v-if="virtual_iplink_members && !isVrrp"
            class="row d-block d-lg-flex flex-lg-row"
        >
            <div
                v-for="(member, index) in virtual_iplink_members"
                :key="index"
                class="col"
            >
                <IplinkMember
                    :iplink-member="member"
                />
            </div>
        </div>
        <VRRPMemberList
            v-if="isVrrp"
            class="row d-block d-lg-flex flex-lg-row"
            @deploy-configuration="handleDeployConfiguration"
            @show-deploy-configuration-modal="handleShowDeployConfigurationModal"
        />
        <div
            v-if="!linkequipment.discharged"
            class="row d-block"
            :class="{
                'd-lg-flex': iplink.mpls,
                'd-md-flex': !iplink.mpls
            }"
        >
            <div class="col">
                <div
                    :class="[
                        $style.connectedDevices,
                        $style.box,
                        $style.stat,
                        $style.clickable
                    ]"
                    @click="change_section('connectedDevices')"
                >
                    <div :class="$style.value">
                        <h1>{{ connectedDevices }}</h1>
                    </div>
                    <div :class="$style.body1">
                        {{ connectedDevicesText }}
                    </div>
                    <div
                        v-if="connectedDevicesTable && connectedDevicesTable[0]"
                        class="mt-2"
                    >
                        <div
                            v-show="connectedDevicesTable[0].last_synchronization_date"
                            :class="$style.b1"
                        >
                            <translate>Last synchro:</translate>
                            <span
                                :class="$style.c1"
                            >
                                {{ connectedDevicesTable[0].last_synchronization_date | moment }}
                            </span>
                        </div>
                        <div
                            v-show="!connectedDevicesTable[0].last_synchronization_date"
                            v-translate
                            :class="$style.b1"
                        >
                            Never synchronized
                        </div>
                    </div>
                </div>
            </div>

            <div class="col">
                <div :class="[$style.active_ports, $style.box, $style.stat]">
                    <div :class="$style.value">
                        <h1>{{ active_ports }}</h1>
                    </div>
                    <div :class="$style.body1">
                        {{ active_ports_text }}
                    </div>
                </div>
            </div>

            <div
                v-if="iplink.mpls"
                class="col"
            >
                <div
                    :class="[
                        $style.configuration_mpls,
                        $style.box,
                        $style.stat,
                        $style.clickable
                    ]"
                    @click="showMplsRulesModal()"
                >
                    <div :class="[$style.value, $style.good]">
                        <h1 v-translate>
                            VPN MPLS
                        </h1>
                    </div>
                    <div
                        v-translate="{count: active_mplsrules}"
                        :translate-n="active_mplsrules"
                        translate-plural="%{count} active rules"
                        :class="$style.body1"
                    >
                        %{count} active rule
                    </div>
                </div>
            </div>
        </div>

        <div
            v-if="!linkequipment.discharged && has_capability('interfaces')"
            class="row"
        >
            <div class="col">
                <div :class="$style.box">
                    <GeneralPorts @deploy-configuration="handleDeployConfiguration" />
                </div>
            </div>
        </div>

        <div
            v-if="!linkequipment.discharged && linkequipmentconf"
            class="row"
        >
            <div class="col">
                <div :class="$style.box">
                    <GeneralInterfaces />
                </div>
            </div>
        </div>

        <div
            class="row"
        >
            <div class="col-md-12 col-lg-6 col-sm-12">
                <div :class="[$style.last_config, $style.box, $style.textbox]">
                    <div :class="$style.title">
                        <h3 v-translate>
                            Last configuration deployed
                        </h3>
                    </div>
                    <div v-if="linkequipment.last_synchronization_date">
                        <div :class="[$style.text, $style.body1]">
                            {{ linkequipment.last_synchronization_by }}
                            <translate>on</translate>
                            {{ linkequipment.last_synchronization_date | moment }}
                        </div>
                        <div :class="$style.comment">
                            {{ linkequipment.last_synchronization_comment }}
                        </div>
                    </div>
                    <div v-else>
                        <div
                            v-translate
                            :class="[$style.text, $style.body1]"
                        >
                            Configuration has not yet been deployed
                        </div>
                    </div>
                </div>
                <div :class="[$style.config_comments, $style.box, $style.textbox]">
                    <div :class="$style.title">
                        <h3 v-translate>
                            Comment attached to equipment
                        </h3>
                    </div>
                    <div
                        :class="$style.body1"
                    >
                        <span class="d-inline">
                            {{ linkequipment.comment || $gettext('No comment') }}
                        </span>
                        <Button
                            v-if="!linkequipment.discharged"
                            class="d-inline-flex"
                            role="link"
                            :text="$gettext('(modify)')"
                            @click="showCommentModal()"
                        />
                    </div>
                    <div
                        v-show="linkequipment.comment"
                        class="mt-auto"
                        :class="$style.b1"
                    >
                        <translate>Commented by:</translate>
                        <span :class="[$style.c1, $style.commented_by]">
                            {{ linkequipment.commented_by || 'unyc' }}
                        </span>
                        <span
                            v-translate
                            :class="[$style.c1, $style.commented_by]"
                        >
                            on
                        </span>
                        <span :class="[$style.c1, $style.commented_by]">
                            {{ linkequipment.comment_creation_date | moment }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-md-12 col-lg-6 col-sm-12">
                <div :class="[$style.box, $style.previous_confs]">
                    <Confs />
                </div>
            </div>
        </div>
        <div
            v-if="
                (has_capability('admin_credentials') || can_manage_router) &&
                    canActivateAdminAccount
            "
            class="row"
        >
            <div class="col">
                <Admin />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import Vue from 'vue';
import moment from 'moment';

import { showSwalError } from '@/utils/utils';
import Button from '@/components/common/button.vue';
import Banner from '@/components/common/banner.vue';
import Admin from './admin.vue';
import GeneralPorts from './general/ports/general-ports.vue';
import Confs from './general-confs.vue';
import GeneralInterfaces from './general/interfaces/general-interfaces.vue';
import GeneralEquipmentSpecs from './general-equipment-specifications.vue';
import IplinkMember from './general-iplinkmember.vue';
import VRRPMemberList from './general-vrrpmember-list.vue';

import ReadonlyBanner from './banners/readonly-banner.vue';

import { actionsControl } from './utils/utils';

const AttachModal = () => import(
    /* webpackPreload: true */
    /* webpackChunkName: "equipment-attach" */
    './modals/attach.vue'
);
const MplsRulesModal = () => import(
    /* webpackPreload: true */
    /* webpackChunkName: "equipment-mplsrules" */
    './modals/mplsrules.vue'
);
const CommentModal = () => import(
    /* webpackPreload: true */
    /* webpackChunkName: "equipment-comment" */
    './modals/comment.vue'
);
const ConfTemplateChoicesModal = () => import(
    /* webpackPreload: true */
    /* webpackChunkName: "equipment-conftemplatechoices" */
    './modals/conf-template-choices.vue'
);
const SecurityModal = () => import(
    /* webpackPreload: true */
    /* webpackChunkName: "equipment-security" */
    './modals/security.vue'
);

export default {
    components: {
        Admin,
        GeneralPorts,
        GeneralEquipmentSpecs,
        Confs,
        Button,
        GeneralInterfaces,
        IplinkMember,
        VRRPMemberList,
        Banner,
        ReadonlyBanner,
    },
    filters: {
        moment(date) {
            moment.locale(Vue.config.language);
            return moment(date).format('LLL');
        },
    },
    props: {
        canActivateAdminAccount: {
            type: Boolean,
            default: true,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        missingWanConfiguration: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            fetching_status: false,
        };
    },
    computed: {
        ...mapGetters('equipment', [
            'activatedAccesses',
            'iplink',
            'linkequipment',
            'linkequipmentconf',
            'connectedDevicesTable',
            'interfaces',
            'isRecommendedEquipment',
            'notRecommendedEquipmentReason',
            'mplsrules',
            'hash_prefix',
            'full_capabilities',
            'virtual_iplink_members',
            'security_iplinks',
            'securityIPLinksFounded',
            'virtual_linkequipment_members',
        ]),
        ...mapGetters([
            'permissions',
        ]),
        show_conf_template_banner() {
            if (!this.linkequipmentconf) {
                return false;
            }
            if (this.linkequipmentconf.lans) {
                return false;
            }
            return true;
        },
        show_security_banner() {
            if (this.linkequipment._access.redundancy || this.activatedAccesses.length > 1) {
                return false;
            }
            return this.securityIPLinksFounded && (this.security_iplinks || []).length;
        },
        show_eligibility_banner() {
            if (this.linkequipment._access.redundancy || this.activatedAccesses.length > 1) {
                return false;
            }
            return this.securityIPLinksFounded && !(this.security_iplinks || []).length;
        },
        show_change_equipment_banner() {
            return !this.isRecommendedEquipment;
        },
        active_mplsrules() {
            let mplsRulesCount = 0;

            if (this.mplsrules) {
                this.mplsrules.forEach((equipmentMplsRules) => {
                    if (!equipmentMplsRules.mpls_rules) {
                        return;
                    }
                    mplsRulesCount += equipmentMplsRules.mpls_rules.length;
                });
            }

            return mplsRulesCount;
        },
        can_manage_router() {
            return this.permissions.find(
                element => element.codename === 'can_manage_router' && element.app_label === 'equipments',
            ) !== undefined;
        },
        isVrrp() {
            return this.linkequipment._access.redundancy === 'vrrp';
        },
        active_ports_text() {
            return this.$ngettext(
                'Active port',
                'Active ports',
                this.active_ports,
            );
        },
        active_ports() {
            let activePortsCount = 0;

            if (this.interfaces) {
                this.interfaces.forEach((equipmentConf) => {
                    if (!equipmentConf.interfaces) {
                        return;
                    }
                    activePortsCount += equipmentConf.interfaces.filter(
                        iface => iface.enabled,
                    ).length;
                });
            }

            return activePortsCount;
        },
        connectedDevicesText() {
            return this.$ngettext(
                'Device connected to the equipment',
                'Devices connected to the equipment',
                this.connectedDevices,
            );
        },
        connectedDevices() {
            let connectedDevicesCount = 0;

            if (this.connectedDevicesTable) {
                this.connectedDevicesTable.forEach((element) => {
                    connectedDevicesCount += element.entries_count;
                });
            }

            return connectedDevicesCount;
        },
        linkequipments() {
            if ((this.virtual_linkequipment_members || []).length) {
                return this.virtual_linkequipment_members;
            }
            if (this.linkequipment) return [this.linkequipment];
            return [];
        },
        alertsEquipment() {
            const alertsEquipment = [];
            this.linkequipments.forEach((linkequipment) => {
                if (!linkequipment._equipment) return;
                const accessesNumber = this.activatedAccesses.length;
                const capabilities = linkequipment._equipment._model.capabilities || [];

                if (accessesNumber > 1 && !capabilities.includes('backup')) {
                    alertsEquipment.push({
                        message: this.$gettext('Your equipment is not Backup 4G compatible'),
                        button: {
                            text: this.$gettext('Change equipment'),
                            click: () => {
                                this.showAttachModal(linkequipment);
                            },
                        },
                    });
                }
            });
            return alertsEquipment;
        },
        currentAccess() {
            return (this.linkequipment || {})._access;
        },
    },
    mounted() {
        this.fetchLinksetupequipmentmodels();
    },
    methods: {
        ...mapActions('equipment', [
            'fetch_linksetupequipmentmodels',
        ]),
        ...mapMutations('equipment', [
            'updateRecommendedEquipment',
        ]),
        has_capability(capability) {
            return this.full_capabilities.includes(capability);
        },
        change_section(section) {
            const currenthash = this.$route.hash;
            const sectionhash = `#${this.hash_prefix}${section}`;

            if (currenthash !== sectionhash) {
                this.$router.push(sectionhash);
            }
        },
        showMplsRulesModal() {
            this.show_modal(MplsRulesModal);
        },
        showCommentModal() {
            this.show_modal(
                CommentModal,
                {},
                {
                    maxWidth: 580,
                    maxHeight: 430,
                },
            );
        },
        showConfTemplateChoicesModal() {
            this.show_modal(
                ConfTemplateChoicesModal,
                {},
                {
                    maxHeight: 450,
                    maxWidth: 900,
                },
            );
        },
        showSecurityModal() {
            actionsControl(this.linkequipment).then(() => {
                this.show_modal(
                    SecurityModal,
                    {},
                    {
                        height: 'auto',
                        maxWidth: 820,
                    },
                );
            }).catch(showSwalError);
        },
        redirectToEligibility() {
            const routeData = this.$router.resolve({ name: 'eligibility' });
            window.open(
                routeData.href,
                '_blank',
            );
        },
        showAttachModal(linkequipment) {
            const iplink = linkequipment._access._iplink;

            const accessesNumber = this.activatedAccesses.length;
            const capabilities = [`can_manage_${iplink.ipstack}`];
            if (this.linkequipment._access.redundancy === 'multiwan' || accessesNumber > 1) {
                capabilities.push('can_multiwan');
            }
            if (accessesNumber > 1) {
                capabilities.push('backup');
            }

            actionsControl(this.linkequipment)
                .then(() => this.show_modal(
                    AttachModal,
                    {
                        iplink,
                        action: 'update',
                        capabilities,
                    },
                    {
                        maxWidth: Infinity,
                        maxHeight: Infinity,
                    },
                ))
                .then(data => this.updateRecommendedEquipment({
                    isRecommended: data.recommended,
                    reason: data.reason,
                }))
                .catch(showSwalError);
        },
        handleDeployConfiguration(params) {
            this.$emit('deploy-configuration', params);
        },
        handleShowDeployConfigurationModal(params) {
            this.$emit('show-deploy-configuration-modal', params);
        },
        fetchLinksetupequipmentmodels() {
            if (this.currentAccess.redundancy) {
                return;
            }

            const params = {
                filters: {
                    collect: this.iplink.collect,
                    mpls: this.iplink.mpls,
                    download_rate: this.currentAccess.real_download_rate,
                    techno: this.currentAccess.link_type,
                    authentification_type: this.iplink.authentification_type,
                    modem_type: this.currentAccess.modem_type,
                    access: this.currentAccess.link_id,
                    router_vendor: this.linkequipment._equipment._model._vendor.id,
                    router_model: this.linkequipment._equipment._model.id,
                },
                updateRecommendedValue: true,
            };
            this.fetch_linksetupequipmentmodels(params);
        },
    },

};
</script>

<style lang="scss" module>
.general {
    padding: 0;

    h1 {
        @include under-to(md) {
            font-size: 28px;
            font-weight: bold;
            line-height: 1.61;
        }
    }

    .stat {
        .value {
            display: flex;
            align-items: center;

            &.good:after {
                width: 34px;
                height: 34px;
                margin-bottom: 0.5rem;
                margin-left: 20px;
                text-align: center;
                vertical-align: middle;
                content: " ";
                border-radius: 17px;
                mask-size: contain;
                mask-repeat: no-repeat;
                mask-position: center;
                mask-image: url('./assets/check.svg?inline');

                @include themify($themes) {
                    color: themed('success_color');
                    background-color: themed('success_color');
                }
            }
        }
    }

    .box {
        padding: 20px 30px;
        border-radius: 4px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.07);

        @include under-to(md) {
            padding: 20px 20px;
        }

        &.clickable {
            cursor: pointer;
        }

        .top {
            margin-bottom: 10px;

            @include under-to(md) {
                margin-bottom: 0;
            }
        }
    }

    .config_comments {
        display: flex;
        flex-direction: column;
    }

    .commented_by {
        @include themify($themes) {
            color: themed('main_color');
        }
    }

    .last_config {
        margin-bottom: 20px;

        & .comment {
            max-height: 3em;
            margin-top: 5px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    & > :global(.row) {
        display: flex;
        flex-wrap: wrap;
    }

    :global([class*="col"]) {
        padding-right: 10px;
        padding-left: 10px;
    }

    & > :global(.row > [class*='col']) {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;

        & > * {
            height: 100%;
        }
    }

    .b4gTitle {
        margin-bottom: 10px;
        font-weight: 500;
    }
}
</style>
